'use strict';

import '../../stylesheets/pages/home.scss';

import ApplicationLayout from '../components/layouts/application';

require('./common');

const root = ReactCreateRoot(document.getElementById('home-component'));
root.render(
    <ApplicationLayout isHome={true}
                       componentId="home-component"/>
);
